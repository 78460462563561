import iconVk from '../images/platform/vk-icon.webp';
import iconOk from '../images/platform/ok-icon.webp';
import iconTg from '../images/platform/telegram.webp';

export const smartberry = {
    email: 'smartberry@mail.ru',
    platforms: {
        vk: {
            logo: iconVk,
            url: 'https://vk.com/smartberry_games',
        },
        ok: {
            logo: iconOk,
            url: 'https://ok.ru/group/70000005108745',
        },
        telegram: {
            logo: iconTg,
            url: 'https://t.me/smartberry_news',
        },
    },
};
