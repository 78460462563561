import iconVk from '../images/platform/vk-icon.webp';
import iconOk from '../images/platform/ok-icon.webp';
import iconYg from '../images/platform/yandex-games-icon.webp';
import iconCrazyGames from '../images/platform/crazy-games-purple-icon.webp';
import iconGooglePlay from '../images/platform/google-play-icon.webp';
import iconGM from '../images/platform/gamemonetize-icon.webp';
import iconGD from '../images/platform/gd.webp';
import iconGamePix from '../images/platform/game-pix-icon.webp';
import iconPoki from '../images/platform/poki-icon.webp';
import iconAppStore from '../images/platform/apple-app-store-icon.webp';
import iconAppGallery from '../images/platform/huawei-app-gallery-icon.webp';
import iconRuStore from '../images/platform/ru-store-icon.webp';
import iconNashStore from '../images/platform/nash-store-icon.webp';
import iconPlaydeck from '../images/platform/telegram.webp';
import iconWGPlayground from '../images/platform/wg_playground.webp';

export const platformsConfig =  {
    yandexGames: {
        icon: iconYg,
        url: 'https://yandex.ru/games/app/',
    },
    crazyGames: {
        icon: iconCrazyGames,
        url: 'https://www.crazygames.com/game/',
    },
    ok: {
        icon: iconOk,
        url: 'https://ok.ru/game/',
    },
    vk: {
        icon: iconVk,
        url: 'https://vk.com/',
    },
    rustore: {
        icon: iconRuStore,
        url: 'https://www.rustore.ru/catalog/app/',
    },
    appGallery: {
        icon: iconAppGallery,
        url: 'https://appgallery.huawei.com/app/',
    },
    googlePlay: {
        icon: iconGooglePlay,
        url: 'https://play.google.com/store/apps/details?id=',
    },
    playdeck: {
        icon: iconPlaydeck,
        url: 'https://t.me/playdeckbot/game?startapp=',
    },
    gameMonetize: {
        icon: iconGM,
        url: 'https://gamemonetize.com/',
    },
    gamePix: {
        icon: iconGamePix,
        url: 'https://www.gamepix.com/play/',
    },
    wgPlayground: {
        icon: iconWGPlayground,
        url: 'https://www.wgplayground.com/game/gamepush/',
    },
    poki: {
        icon: iconPoki,
        url: 'https://poki.com/ru/g/',
    },
    gameDistribution: {
        icon: iconGD,
        url: 'https://html5.gamedistribution.com/',
    },
    appStore: {
        icon: iconAppStore,
        url: 'https://apps.apple.com/by/app/',
    },
    nashStore: {
        icon: iconNashStore,
        url: 'https://store.nashstore.ru/store/',
    },
};