import { Flex, Text } from '@mantine/core';
import { CiMail } from 'react-icons/ci';
import { ImageReDirect } from '../ImageReDirect/ImageReDirect';
import { smartberry } from '../../constant';
import style from './Footer.module.scss';
import { t } from '../../language/translations';

export const Footer = () => {
    const mailTo = `mailto:${smartberry.email}`;
    return (
        <Flex align="center" direction="column" gap={2}>
            <Flex align="center" justify="center" h={'100%'} gap={8} style={{ marginBottom: 8 }}>
                {Object.values(smartberry.platforms).map(p => (
                    <ImageReDirect key={p.url} src={p.logo} url={p.url} maw={32} />
                ))}
                <Flex gap={4} align={'center'}>
                    <a href={mailTo} className={style.link}>
                        <CiMail size={32} />
                    </a>
                    {/* <a href={mailTo} className={style.link}>
                        <Text fz={18}>{smartberry.email}</Text>
                    </a> */}
                </Flex>
            </Flex>
            <div className={style.text}>{t('text/weDevelopAndPublish')}</div>
            {/* <div className={style.text}>{t('text/weHelpYouReleaseGame')}</div> */}
            <div className={style.text}>
                {t('text/toCooperateWriteTo')}
                {` `}
                <a href={mailTo} className={style.textLink}>
                    {smartberry.email}
                </a>
            </div>
        </Flex>
    );
};
