import React from 'react';

import { Flex, Text } from '@mantine/core';

import { t } from '../../language/translations';
import { ImageReDirect } from '../ImageReDirect/ImageReDirect';
import { YandexMetrica } from '../../analytics/YandexMetrica';
import { platformsConfig } from '../../constant/platformsConfig';

const Card = ({ game, gameCode = '' }) => {
    const { logo = '', title = '', mainPlatformCode = '', platforms = {} } = game;
    const mainPlatform = platforms[mainPlatformCode];
    const mainPlatformConfig = platformsConfig[mainPlatformCode];

    const onClick = (game, platform, isCover) => {
        YandexMetrica.gameClickPlay({
            game: YandexMetrica.Game[game],
            platform: YandexMetrica.Platform[platform],
            linkType: isCover ? YandexMetrica.LinkType.cover : YandexMetrica.LinkType.platformIcon,
        });
    };

    return (
        <Flex direction={'column'} gap={8} w={'fit-content'} maw={424}>
            <div>
                <Text size={'xl'} fw={700}>
                    {t(title)}
                </Text>
                <ImageReDirect
                    src={logo}
                    url={`${mainPlatformConfig.url}${mainPlatform.path}`}
                    style={{
                        aspectRatio: 1.7,
                        objectFit: 'cover',
                    }}
                    onClick={() => onClick(gameCode, mainPlatformCode, true)}
                    onAuxClick={ev => {
                        // on mouse wheel click
                        if (ev.button === 1) onClick(gameCode, mainPlatformCode, true);
                    }}
                />
            </div>

            <Flex align={'start'} gap={8} direction={'column'} w={'100%'}>
                <Text fw={500}>{t('play')}:</Text>
                <Flex gap={12} align={'center'} wrap={'wrap'} w={'100%'}>
                    {Object.entries(platforms).map(([pCode, p]) => (
                        <ImageReDirect
                            key={p.url}
                            src={p.icon ?? platformsConfig[pCode].icon}
                            url={`${platformsConfig[pCode].url}${p.path}`}
                            h={48}
                            style={{
                                ...(pCode === 'nashStore' && nashStoreIconStyle),
                            }}
                            onClick={() => onClick(gameCode, pCode, false)}
                            onAuxClick={ev => {
                                // on mouse wheel click
                                if (ev.button === 1) onClick(gameCode, pCode, false);
                            }}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};
export default Card;

const nashStoreIconStyle = {
    aspectRatio: 1,
    objectPosition: 'center',
    objectFit: 'fill',
};
